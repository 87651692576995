<template>
  <div>
    
    <b-card title="" class="mb-2" hide-footer>
      <v-tabs 
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows
        background-color="white" 
        color="accent-4" 
        left>
        <v-tab>{{ $t('PAGES.IMPORT.ACTIVE') }}</v-tab>
        <v-tab>{{ $t('PAGES.IMPORT.HISTORY') }}</v-tab>
        
        <v-tab-item>
          <ImportTabActive variant="MEMBER" />
        </v-tab-item>

        <v-tab-item>
          <ImportTabHistory variant="MEMBER" />
        </v-tab-item>

      </v-tabs>
    </b-card>
  </div>
</template>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ImportTabActive from '@/view/pages/ml/import/ImportTabActive.vue';
import ImportTabHistory from '@/view/pages/ml/import/ImportTabHistory.vue';

export default {
  name: 'import-page',
  mixins: [ toasts ],
  components: {
    ImportTabActive,
    ImportTabHistory
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'isTHS'])
  },
  
  data() {
    return {
      
    };
  },
  watch: {
   
  },
  methods: {
    
  }
};
</script>
